export const uniqBy = <T>(items: T[], transformer: (item: T) => string) => {
  const seen: { [key: string]: 1 } = {};

  return items.filter(item => {
    const transformed = transformer(item);
    if (seen[transformed]) return false;

    seen[transformed] = 1;
    return true;
  });
};

export const groupBy = <T>(items: T[], grouper: (item: T) => string) => {
  const groups: { [key: string]: T[] } = {};

  items.forEach(item => {
    const group = grouper(item);
    if (!groups[group]) {
      groups[group] = [item];
    } else {
      groups[group].push(item);
    }
  });

  return groups;
};
