import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import enAU from 'date-fns/locale/en-AU';
import { useAtomValue } from 'jotai';
import React, { ReactNode, Suspense } from 'react';
import { BrowserRouter, Navigate, Route, Routes, useParams } from 'react-router-dom';
import { Loading } from './Components/Loading';
import { Authed } from './auth/AuthedRoute';
import { SignIn } from './auth/SignIn';
import { IS_SIGNED_IN } from './store/auth';
import { LIVE_LAST_EVENTS_FROM_PERSISTOR, LIVE_TIME } from './store/liveEvents';
import { REPLAY_LAST_EVENTS_FROM_PERSISTOR, REPLAY_NOW, REPLAY_START } from './store/replayEvents';
import { CID, PID } from './store/user';
import { EditingProvider } from './underground/map/EditTools/useEditingData';
import { AppProvider } from './util/AppProvider/AppProvider';
import { ReplayEventsProvider } from './util/Events/ReplayEventsProvider';
import { Themed } from './util/Themed/Themed';

const Deferred = ({ children }: { children: ReactNode }) => (
  <Suspense fallback={<Loading />}>{children}</Suspense>
);

const AcceptInvite = React.lazy(() => import('./auth/AcceptInvite'));
const AuthError = React.lazy(() => import('./auth/ProjectSelection'));
const ProjectSelection = React.lazy(() => import('./auth/ProjectSelection'));
const AccountRecovery = React.lazy(() => import('./auth/AccountRecovery'));
const UserAccount = React.lazy(() => import('./UserAccount/UserAccount'));
const SuperAdmin = React.lazy(() => import('./SuperAdmin/SuperAdmin'));
const LiveEventsProvider = React.lazy(() => import('./util/Events/LiveEventsProvider'));
const LatestEventsProvider = React.lazy(() => import('./util/Events/LatestEventsProvider'));
const MapPage = React.lazy(() => import('./underground/map/MapPage/MapPage'));

export const App = () => (
  <AppProvider>
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enAU}>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Themed mode='base'><SignIn /></Themed>} />
          <Route path='/accept-invite' element={<Themed mode='base'><Deferred><AcceptInvite /></Deferred></Themed>} />
          <Route path='/sign-in' element={<Themed mode='base'><SignIn /></Themed>} />
          <Route path="/auth/error" element={<Themed mode='base'><Deferred><AuthError /></Deferred></Themed>} />
          <Route path='/project-selection' element={<Themed mode='base'><Deferred><ProjectSelection /></Deferred></Themed>} />
          <Route path="/account-recovery" element={<Themed mode='base'><Deferred><AccountRecovery /></Deferred></Themed>} />
          <Route
            path="/my-account"
            element={<Themed mode='base'>
              <Deferred>
                <Authed>
                  <UserAccount />
                </Authed>
              </Deferred>
            </Themed>}
          />
          <Route
            path='/super-admin'
            element={<Deferred>
              <Authed>
                <SuperAdmin />
              </Authed>
            </Deferred>
            }
          />
          <Route path='/map' element={<Deferred>
            <Authed>
              <LiveEventsProvider>
                <LatestEventsProvider
                  hoursAgo={2}
                  timeNowAtom={LIVE_TIME}
                  latestEventsAtom={LIVE_LAST_EVENTS_FROM_PERSISTOR}
                >
                  <MapPageIdentified mode='live' />
                </LatestEventsProvider>
              </LiveEventsProvider>
            </Authed>
          </Deferred>} />
          <Route path='/map/replay' element={<Deferred>
            <Authed>
              <ReplayEventsProvider>
                <LatestEventsProvider
                  hoursAgo={2}
                  timeNowAtom={REPLAY_START}
                  latestEventsAtom={REPLAY_LAST_EVENTS_FROM_PERSISTOR}
                >
                  <MapPageIdentified mode='replay' />
                </LatestEventsProvider>
              </ReplayEventsProvider>
            </Authed>
          </Deferred>} />
          <Route path='/map/edit' element={<Deferred>
            <Authed>
              <EditingProvider>
                <MapPageIdentified mode='edit' />
              </EditingProvider>
            </Authed>
          </Deferred>} />
          <Route
            path='*'
            element={
              useAtomValue(IS_SIGNED_IN) ? (
                <Navigate to='/project-selection' />
              ) : (
                <Navigate to='/sign-in' />
              )
            }
          />
        </Routes>
      </BrowserRouter>
    </LocalizationProvider>
  </AppProvider>
);

const MapPageIdentified = ({ mode }: { mode: 'live' | 'replay' | 'edit' }) => {
  const cid = useAtomValue(CID);
  const pid = useAtomValue(PID);
  return <MapPage cid={cid} pid={pid} mode={mode} />;
}