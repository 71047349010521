import { Box, Container, Grid, Paper, Stack, CircularProgress } from "@mui/material";

export const Loading = () => (
  <Box component='div'
    sx={{
      width: '100vw',
      height: '100vh',
      background: 'linear-gradient(90.26deg, #4CB8C4 0.14%, #3CD3AD 99.83%)',
    }}
  >
    <Grid container direction="column" justifyContent="center" height="100%">
      <Container component="main" maxWidth="xs">
        <Stack direction="column" spacing={2}>
          <img src='/Logo_large.gif' alt="Logo Geomoby" width="396px" height="139px" style={{ maxWidth: "100%", objectFit: 'contain', objectPosition: 'center bottom' }} />
          <Paper elevation={5}>
            <Grid container direction="column" justifyContent="center" alignItems="center" py={5}>
              <CircularProgress color="inherit" size='100px' />
            </Grid>
          </Paper>
        </Stack>
      </Container>
    </Grid>
  </Box>
);
