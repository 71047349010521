import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './App';
import reportWebVitals from './reportWebVitals';

const container = document.getElementById('root');
if (!container) throw new Error('Missing root element for React to mount');
const root = createRoot(container);
root.render(
  // <StrictMode>
    <App />
  // </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Delay loading some things until after initial page load
window.addEventListener('load', () => {
  setTimeout(() => {
    const conatiner = document.createElement('div');
    conatiner.style.display = 'none';
    [
      "icon-mining-truck.svg",
      "icon-pickup-truck.svg",
      "GatewayPin.svg",
      "MqttGatewayPin.svg",
      "LocatedPin.svg",
      "LocatorPin.svg",
      "PersonPin.svg",
      "questionMarkCircle.svg",
      "TruckPin.svg",
      "UtePin.svg",
    ].map(src => {
      const img = document.createElement('img');
      img.setAttribute('src', src);
      conatiner.appendChild(img);
    });
    document.body.appendChild(conatiner);
  }, 3000);
});